import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const ThankYouPage = () => {
  const [conversionTracked, setConversionTracked] = useState(false);
  const location = useLocation();
  const { orderId, currency, cartTotal } = location.state;
  useEffect(() => {
    if (!conversionTracked) {
      const cartTotalString = cartTotal.toString();
      const orderIdString = orderId.toString();
      console.log("strings", cartTotalString, orderIdString);
      window.etag("event", "conversion", {
        value: cartTotalString,
        order_id: orderIdString,
        currency: currency,
        transaction_id: "TRANSACTION_ID",
        customer_email: "CUSTOMER_EMAIL",
        customer_id: "CUSTOMER_ID",
        customer_first_name: "CUSTOMER_FIRST_NAME",
        customer_last_name: "CUSTOMER_LAST_NAME",
        customer_total_spent: cartTotalString,
        customer_phone: "CUSTOMER_PHONE",
        customer_accepts_marketing: "CUSTOMER_ACCEPTS_MARKETING",
      });

      setConversionTracked(true);
    }
  }, [conversionTracked, orderId, currency, cartTotal]);

  return (
    <div className="container mt-5 mb-5">
      <div className="row justify-content-center mt-5">
        <div className="col-md-6">
          <div className="card mt-5">
            <div className="card-body">
              <h2 className="card-title text-center mb-4">
                Success Notification
              </h2>
              <p className="text-success text-center">
                Thank you for Ordering!
              </p>
              <p className="text-success text-center">Success your Payment</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThankYouPage;
