import React from 'react';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import GoogleIcon from '@mui/icons-material/Google';

export default () => {
  return(
    <footer className="section footer-classic context-dark bg-image">
        <div className="container">
          <div className="row row-30 footer-row">
            <div className="col-md-4 col-xl-5">
              <div className="pr-xl-4">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                <p className="rights"><span>©  </span><span className="copyright-year">2018</span><span> </span><span>. </span><span>All Rights Reserved.</span></p>
              </div>
            </div>
            <div className="col-md-4">
              <h5>Contacts</h5>
              <dl className="contact-list">
                <dt>Address:</dt>
                <dd>Hyderabad, India</dd>
              </dl>
              <dl className="contact-list">
                <dt>email:</dt>
                <dd><a href="mailto:#">info@example.com</a></dd>
              </dl>
              <dl className="contact-list">
                <dt>phones:</dt>
                <dd><a href="tel:#">040-99999999</a> <span>or</span> <a href="tel:#">+91 9876543210</a>
                </dd>
              </dl>
            </div>
            <div className="col-md-4 col-xl-3">
              <h5>Links</h5>
              <ul className="nav-list">
                <li><a href="#about">About</a></li>
                <li><a href="#projects">Projects</a></li>
                <li><a href="#blog">Blog</a></li>
                <li><a href="#contacts">Contacts</a></li>
                <li><a href="#pricing">Pricing</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row no-gutters social-container">
          <div className="col"><a className="social-inner" href="#facebook"><div className='d-flex align-items-center'><FacebookIcon sx={{marginRight: 1}}/><span>Facebook</span></div></a></div>
          <div className="col"><a className="social-inner" href="#instagram"><div className='d-flex align-items-center'><InstagramIcon sx={{marginRight: 1}}/><span>instagram</span></div></a></div>
          <div className="col"><a className="social-inner" href="#twitter"><div className='d-flex align-items-center'><TwitterIcon sx={{marginRight: 1}}/><span>twitter</span></div></a></div>
          <div className="col"><a className="social-inner" href="#google"><div className='d-flex align-items-center'><GoogleIcon sx={{marginRight: 1}}/><span>google</span></div></a></div>
        </div>
      </footer>
  );
}
