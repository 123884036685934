import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { generateUniqueId } from "../helpers/generateUniqueId";

const CheckoutPage = () => {
  const history = useHistory();
  const location = useLocation();
  const { cartTotal } = location.state;
  const [formData, setFormData] = useState({
    cardNumber: "",
    expiryDate: "",
    cvv: "",
    cardHolderName: "",
  });
  const [errors, setErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    let errors = {};
    let isValid = true;

    if (
      !formData.cardNumber ||
      formData.cardNumber.length !== 12 ||
      isNaN(formData.cardNumber)
    ) {
      errors.cardNumber = "Please enter a valid 12-digit card number";
      isValid = false;
    }

    if (
      !formData.expiryDate ||
      !/^(0[1-9]|1[0-2])\/\d{2}$/.test(formData.expiryDate)
    ) {
      errors.expiryDate =
        "Please enter a valid expiry date in the format MM/YY";
      isValid = false;
    }

    if (!formData.cvv || formData.cvv.length !== 3 || isNaN(formData.cvv)) {
      errors.cvv = "Please enter a valid 3-digit CVV";
      isValid = false;
    }

    if (
      !formData.cardHolderName ||
      !/^[a-zA-Z ]+$/.test(formData.cardHolderName)
    ) {
      errors.cardHolderName = "Please enter a valid card holder name";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setSubmitting(true);
      setTimeout(() => {
        // Generate unique order ID
        const orderId = generateUniqueId();
        setSubmitting(false);
        setTimeout(() => {
          history.push({
            pathname: "/ThankYouPage",
            state: { orderId, currency: "USD", cartTotal }, // Pass order ID, currency, and cart total as state
          });
        }, 3000);
      }, 4000);
    }
  };

  const formatExpiryDate = (input) => {
    let value = input.replace(/\D/g, "");
    if (value.length > 2) {
      value = value.slice(0, 2) + "/" + value.slice(2);
    }
    return value.slice(0, 5);
  };
  const handleExpiryDateChange = (e) => {
    const formattedValue = formatExpiryDate(e.target.value);
    setFormData({
      ...formData,
      expiryDate: formattedValue,
    });
  };
  return (
    <div className="container mt-5 mb-5">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card mt-5">
            <div className="card-body">
              <h2 className="card-title text-center mb-4">
                Enter Card Details
              </h2>
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label>Card Number(Credit/Debit)</label>
                  <input
                    type="number"
                    name="cardNumber"
                    value={formData.cardNumber}
                    placeholder="Enter your card number"
                    onChange={handleInputChange}
                    className={`form-control ${
                      errors.cardNumber ? "is-invalid" : ""
                    }`}
                    pattern="[0-9]{12}"
                  />
                  {errors.cardNumber && (
                    <div className="invalid-feedback">{errors.cardNumber}</div>
                  )}
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label>Expiry Date (MM/YY)</label>
                    <input
                      type="text"
                      name="expiryDate"
                      placeholder="MM/YY"
                      value={formData.expiryDate}
                      onChange={handleExpiryDateChange}
                      maxLength="5"
                      className={`form-control ${
                        errors.expiryDate ? "is-invalid" : ""
                      }`}
                    />
                    {errors.expiryDate && (
                      <div className="invalid-feedback">
                        {errors.expiryDate}
                      </div>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label>CVV</label>
                    <input
                      type="number"
                      name="cvv"
                      maxLength="3"
                      value={formData.cvv}
                      onChange={handleInputChange}
                      className={`form-control ${
                        errors.cvv ? "is-invalid" : ""
                      }`}
                    />
                    {errors.cvv && (
                      <div className="invalid-feedback">{errors.cvv}</div>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <label>Card Holder Name</label>
                  <input
                    type="text"
                    name="cardHolderName"
                    minLength="3"
                    value={formData.cardHolderName}
                    onChange={handleInputChange}
                    className={`form-control ${
                      errors.cardHolderName ? "is-invalid" : ""
                    }`}
                  />
                  {errors.cardHolderName && (
                    <div className="invalid-feedback">
                      {errors.cardHolderName}
                    </div>
                  )}
                </div>
                <button
                  type="submit"
                  className="btn btn-primary btn-block"
                  disabled={submitting}
                >
                  {submitting ? "Submitting..." : "Submit"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutPage;
