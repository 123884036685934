import React from "react";
import logo from "../../assets/logo.png";
import "../../index.css";

export default () => {
  const text = "Community Fairground Store";
  const words = text.split(" ");

  return (
    <div className="header" style={{ backgroundColor: "#7be7f4" }}>
      <div className="container text-center d-flex justify-content-center p-1">
        <div className="">
          <a href="/">
            <img
              src={logo}
              style={{ width: 100, height: 100 }}
              alt="online Store"
            />
          </a>
        </div>
        <div>
          <h1 style={{ color: "#007bff", fontFamily: "monospace" }}>
            {words.map((word, index) => (
              <span key={index}>
                <span style={{ color: "#ff6200" }}>{word.charAt(0)}</span>
                {word.slice(1)}{" "}
              </span>
            ))}
          </h1>
          <p>
            Enjoy the fast shopping cart experience in online{" "}
            <span style={{ fontWeight: 600 }}>SuperMarket</span>
          </p>
        </div>
      </div>
    </div>
  );
};
